import {  Col, Container, Form, Row } from "react-bootstrap";
import Button from '@mui/material/Button';
import Header from "../../components/Header";

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";






export default function AccountType() {
    const [validated, setValidated] = useState(false);

    const navigate= useNavigate()
    const [credidentials, setCredidentials] = useState({
      username: undefined,
      password: undefined
    })



    const handleChange = (e) => {
      setCredidentials((prev) =>({ ...prev, [e.target.id]: e.target.value }) )
    }
    

    const handleSubmit = async (event) => {
        event.preventDefault()
       

        try{
          const res= await axios({
            method: 'post',
            url: 'https://api.komorotel.com/api/auth/register',
            headers:{'Content-Type': 'application/json'}, 
            data: {
              email: credidentials.email,
              name: credidentials.name,
              surname: credidentials.surname,
              password: credidentials.password
            }
          })

          navigate("/signup/emailverification")
          console.log(res)
        }catch(err){
          console.log(err)
        }
        

            };
    return(
        <div className="">
            <Header disabled={true}/>
            
            <Row className="pt-7 h-100 w-100">
                <Col sm={{}} className="mt-5 w-100 d-flex flex-column align-items-center justify-content-center ">
                    
                        <div className=" d-flex flex-column shadow-sm p-4 rounded-3 border h-100   ">
                            <p className="fw-bold fs-5">Se connecter ou créer un compte</p>
                            <p className="fw-light">1- Choisissez quelle type de compte vous voulez créer</p>
                            <Button as={Link} to={'/business/signup'} color="primary" className="fs-5 text-center fw-bold text-decoration-none border p-2 my-4" >Créer un compte Entreprise</Button>

                            <Button as={Link} to={'/signup/user'} color="primary" className="fs-5 text-center fw-bold text-decoration-none border p-2 my-4 ">Créer un compte personnel</Button>


                            
                        </div>
        

                    
                </Col>
            </Row>
            
        </div>
        )
}