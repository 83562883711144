import { createContext, useReducer } from "react";

const {INITIAL_STATE}= {
    city: undefined,
    dates:[],
    options:{
        adults:undefined,
        children: undefined,
        rooms:undefined
    }
}

export const SearchContest= createContext(INITIAL_STATE)

const SearchReducer = (state, action) =>{
    switch(action.type){
        case "NEW_SEARCH":
            return action.payload
        case "RESET_SEARCH":
            return INITIAL_STATE
            default:
                return state
    }
}

export const SearchContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(SearchReducer, INITIAL_STATE)

    return(
        <SearchContest.Provider
        value={{
            city: state?.destination,
            hotelName: state?.hotelName,
            dates: state?.dates,
            options: state?.options,
            dispatch
        }}>
            {children}
        </SearchContest.Provider>
        )
    }