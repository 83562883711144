import { Eventcalendar } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import useFetch from "../../clients/features/get";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Container, Dropdown, Row } from "react-bootstrap"
import { FaCalendarAlt } from "react-icons/fa";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import axios from "axios";


import { useContext, useEffect, useState, } from "react";

export default function Calendar(){
  const [list, updateList]= useState([])
  const hotelParams= useParams()
  const hotelId= hotelParams.hotelId
  const [openValue, setOpenValue] = useState(false)
  const [dataRoom, setDataRoom]= useState()
  const navigate= useNavigate()



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(dataRoom)
      // Send Axios request here
    }, 3000)

    return () => clearTimeout(delayDebounceFn)
  }, [dataRoom])

  const {data, loading, error, refreshData}= useFetch(`https://api.komorotel.com/api/hotels/rooms/${hotelId}`)

  const [date, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const getDateInRange = (start, end) => {
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0)
    start.setMilliseconds(0)
    const date= new Date(start.getTime())
    const list=[]

    while(date <= end){
        list.push(new Date(date).getTime())
        date.setDate(date.getDate()+1)
    }

    return list
}

const allDates= getDateInRange(date[0].startDate, date[0].endDate)

  const isAvailable= (roomNumber) => {
        const isFound=roomNumber.unavailableDates.some((element, index)=>
        
        element[0].some((date) => 
            allDates.includes(new Date(date).getTime())  
            
        )
    )
        return !isFound
    }
  
  const array = []
  const color= ["green", "blue", "purple", "red", "yellow", "brown"]
  useEffect(() => {
        data?.map((room, i) => 
          room.roomNumbers.map((roomNumbers) =>
            roomNumbers.unavailableDates.map((date)=>{
              array.push({start: date[0][0], end:date[0][date[0].length-1], title: `${room.title} ${roomNumbers.number} Occupé · ${date[1]} `, color:color[i], id: roomNumbers._id, cssClass:'d-flex align-items-center justify-content-center fw-bold py-3 m-1'}) 
              updateList(array)
              
          },
          
          
        )))
  }, [loading])

  const handleRoom = async(roomId) => {
    const res = await fetch(`https://api.komorotel.com/api/rooms/${roomId}`).then((res) => res.json())
    setOpenValue(true)
    setDataRoom(res)
  }
  
  

  return(
    !list[0]  ?  
      <Eventcalendar
        width='1200px'
        height='1200px'
        theme="material"
        themeVariant="light"
      />  :
      <div className="border">
        
       

        <Eventcalendar 
          data={list.map((element)=> element)}
          width='1200px'
          height='1200px'
          theme="material"
          themeVariant="light"
          onEventClick={(element) => handleRoom(element.event.id)}
          
        />
        
        {openValue && 
        <div className=" position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center z-2"  style={{"backgroundColor":"rgba(0, 0, 0, 0.418)"}}>
        <Container  className="bg-white d-none d-md-block w-50 h-75 p-2 rounded overflow-auto ">
            <button className="position-fixed start-75 btn-close" onClick={() => setOpenValue(false)} type="button" aria-label="Close"></button>
            <div className="d-flex flex-column mx-3 mt-3">
                
                    <div className="d-flex justify-content-between mx-5 mt-2 pb-7 border-bottom">
                        <div className="w-100 d-flex flex-column align-items-center ">
                            <div className="d-flex w-100">
                                <p className="m-0 w-25 fs-5 me-2 fw-bold">Nom de la chambre:</p> 
                                <p className="fs-6 fw-bold">{dataRoom.title}</p>
                            </div>

                            <div className="d-flex w-100">
                                <p className="m-0 w-25 fs-5 me-2 fw-bold">Description :</p>
                                <p className="text-center fs-5 fw-bold"> {dataRoom.desc}</p>
                            </div>

                            <div className="d-flex w-100">
                                <p className="m-0 w-25 fs-5 me-2 fw-bold">Personnes max:</p>
                                <p className="fs-5 fw-bold">{dataRoom.maxPeople} </p>                                   
                            </div>
                            <div className="d-flex flex-column mt-5">
                            {dataRoom.roomNumbers.map((roomNumber)=>
                            
                                
                            isAvailable(roomNumber) ?
                            <div className="rounded p-3 px-5 mb-4 d-flex flex-column justify-content-between align-items-center bg-primary bg-opacity-25 w-75  ms-5 mt-1">
                             <Dropdown >
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className='d-flex justify-content-center bg-white border border-warning border-3 w-100 py-2 px-2'>
                                    <p className='m-0 ' ><FaCalendarAlt className='me-1 mb-1  '  /> {`${format(date[0].startDate, "dd/MM/yyyy")} à ${format(date[0].endDate, "dd/MM/yyyy")}`}</p>
                                </Dropdown.Toggle>
                                <Dropdown.Menu >
                                    
                                        <DateRange
                                            editableDateInputs={true}
                                            onChange={item => setDates([item.selection])}
                                            moveRangeOnFirstSelection={true}
                                            ranges={date}
                                            
                                            
                                        />
                                </Dropdown.Menu>
                            </Dropdown>
                            <p className="fw-bold fs-3 text-center text-black mt-2 mb-3 text-white">{roomNumber.number}</p> 
                             
                            { roomNumber.unavailableDates.map((element)=>
                            <div className=" d-flex flex-column text-center mb-4 px-3 m-0 border border-white bg-white mt-5 border-4">
                              <p>Réservée du <span className="fw-bold">{format(element[0][0], 'dd/MM/yyyy')}</span> au <span className="fw-bold">{format(element[0][element[0].length-1], 'dd/MM/yyyy')}</span> par <span className="fw-bold">{element[1]}</span></p>
                            </div>
                            )}
                        </div>:
                        <div className="rounded p-3 d-flex flex-column justify-content-between align-items-center bg-danger bg-opacity-25 w-75  ms-5 mt-1">
                             
                             <Dropdown >
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className='d-flex justify-content-center bg-white border border-warning border-3 w-100 py-2 px-2'>
                                    <p className='m-0 ' ><FaCalendarAlt className='me-1 mb-1  '  /> {`${format(date[0].startDate, "dd/MM/yyyy")} à ${format(date[0].endDate, "dd/MM/yyyy")}`}</p>
                                </Dropdown.Toggle>
                                <Dropdown.Menu >
                                    
                                        <DateRange
                                            editableDateInputs={true}
                                            onChange={item => setDates([item.selection])}
                                            moveRangeOnFirstSelection={true}
                                            ranges={date}
                                            
                                            
                                        />
                                </Dropdown.Menu>
                            </Dropdown>
                            <p className="fw-bold fs-3 text-center text-black mt-2 mb-3 text-white">{roomNumber.number}</p> 
                             
                           { roomNumber.unavailableDates.map((element)=>
                            <div className="d-flex flex-column text-center mb-4 px-3 m-0 border border-white bg-white border-4">
                              <p>Réservée du <span className="fw-bold">{format(element[0][0], 'dd/MM/yyyy')}</span> au <span className="fw-bold">{format(element[0][element[0].length-1], 'dd/MM/yyyy')} </span> par <span className="fw-bold">{element[1]}</span></p> 
                            </div>
                            )}
                             
                            
                        </div>
                                    
                                    )}
                                </div>

                        </div>
                            
                            
                        </div>
                
            </div>
            
        </Container>
        </div> }

      </div>
  )
}
