import { Button, Col, Container, Form, Row } from "react-bootstrap";
import UserComponent from "../../components/User";
import Header from "../../components/Header";

import { useContext, useState } from "react";
import Cookies from "js-cookie";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContest } from "../utils/AuthContext";
import axios from "axios";






export default function LoginBus() {
    const [validated, setValidated] = useState(false);
    const navigate= useNavigate()
    const [credidentials, setCredidentials] = useState({
      password: undefined
    })

    const {loading, error, dispatch}= useContext(AuthContest)

    const handleChange = (e) => {
      setCredidentials((prev) =>({ ...prev, [e.target.id]: e.target.value }) )
      console.log(credidentials)
    }
    

    const handleSubmit = async (event) => {       
    
        event.preventDefault();
        dispatch({type: "LOGIN_START"})
        try{
          const res= await axios({
            method: 'post',
            url: 'https://api.komorotel.com/api/business/login',
            headers:{'Content-Type': 'application/json'}, 
            data: {
              email: credidentials.email,
              password: credidentials.password
            }
          })
            
          Cookies.set('access_token', res.data.access_token, {
            path: '/',
          });
            
        

          dispatch({type: "LOGIN_SUCCES", payload: res.data})
          navigate("/")
        }catch(err){
          dispatch({type: "LOGIN_ERROR", payload: err.response.data})
          console.log(err)
        }
        

            };
    return(
      <div>
      <Header disabled={true}/>
      
      <Row className="pt-7">
          <Col sm={{}} className="mt-4 w-100 d-flex flex-column align-items-center justify-content-center ">

          <h1 className=' text-uppercase text-dark fw-bold text-center'><span className=" bg-primary p-2 text-center rounded-3 text-white m-1">Entreprise </span></h1>

              <div className=" d-flex flex-column mt-4 border shadow-sm p-4 mx-3 rounded-3 border h-100   ">
                      <p className="fw-bold fs-5">Se connecter ou créer un compte</p>
                      <p className="fw-light">Entrez vos informations afin de pouvoir profiter de nos services</p>
                      <Form noValidate validated={validated} onSubmit={handleSubmit} className="p-3">
        
                        <Form.Group className="mb-4" md="4" controlId="email">
                        <Form.Label className="fw-bold">Adresse e-mail</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            onChange={handleChange}
                            placeholder="E-mail"
                            
                        />
                        </Form.Group>

                        <Form.Group className="mb-4"  md="4" controlId="password">
                          <Form.Label className="fw-bold">Mot de passe</Form.Label>
                          <Form.Control
                              required
                              type="password"
                              onChange={handleChange}
                              placeholder="Mot de passe"
                              
                          />
                        </Form.Group>
                        
                        
                        <div className="d-flex flex-column">
                            {error && <span className="text-danger text-center">{error.message}</span>}
                            <Button type="submit" disabled={loading} className="mt-3 text-white">Confirmer</Button>
                        </div>
                    
                    </Form>

                      
              </div>
              
                 
  

              
          </Col>
      </Row>
      
      </div>
        
        )
}