import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import CarouselSlide1 from '../data/carousel/1.jpg'
import CarouselSlide2 from '../data/carousel/2.jpg'
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Islands from '../../components/Islands';
import PropertyList from '../../components/PropertyList';
import Footer from '../../components/Footer';
import './css/App.css'
import { useState } from 'react';




function App() {
  const [openDestination, setOpenDestination]= useState(false)
  const image= ["https://cdn.aspas-nature.org/wp-content/uploads/2022/01/Tortue-verte-wikimedia-commons.jpg", "https://www.vanilla-islands.org/wp-content/uploads/2015/12/accueil-comores-plage-barque.jpg", "https://horizonsnomades.fr/wp-content/uploads/les-iles-des-comores-4.jpg", ]
  
  return (
    
    
    <div onClick={()=> setOpenDestination(false)}>
      <Header openDestination={openDestination} setOpenDestination={setOpenDestination}/>
      <Carousel className='mt-7 d-none d-md-block pb-4 px-4'>
        <Carousel.Item>
        <Image src={CarouselSlide1} text="First slide" className=' w-100 d-inline-block object-fit-cover' height='700px'  width="100%" />
          <Carousel.Caption className='d-none d-md-block'>
          <h3>Réservez dès maintenant</h3>
            
          </Carousel.Caption>
        </Carousel.Item>
        {image.map((image, i)=> 
          <Carousel.Item className=''>
            <Image src={image} text="First slide" className=' w-100  d-inline-block object-fit-cover' height='700px'  width="100%" />
          </Carousel.Item>
        )}
        
      </Carousel>
      <Carousel className='mt-7 d-md-none pb-4 px-4'>
        <Carousel.Item>
        <Image src={CarouselSlide1} text="First slide" className=' w-100 d-inline-block object-fit-cover' height='200px'  width="100%" />
          <Carousel.Caption className='d-none d-md-block'>
          <h3>Réservez dès maintenant</h3>
            
          </Carousel.Caption>
        </Carousel.Item>
        {image.map((image, i)=> 
          <Carousel.Item className=''>
            <Image src={image} text="First slide" className=' w-100  d-inline-block object-fit-cover' height='200px' width="100%" />
          </Carousel.Item>
        )}
      </Carousel>

      <Islands/>
      <PropertyList/>
      <Footer/>

      
      </div>
   
  );
}

export default App;
