import { Button, Container } from "react-bootstrap"
import useFetch from "../clients/features/get"
import { useContext, useEffect, useState } from "react"
import { SearchContest } from "../clients/utils/SearchContext"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { AuthContest } from "../clients/utils/AuthContext"
import { format } from "date-fns"

export default function Reserve({setOpen, hotelId, days, cancelRoom, handleCancel, hotel}){

    const {data, loading, error}= useFetch(`https://api.komorotel.com/api/hotels/rooms/${hotelId}`)
    const [selectedRoom, setSelectedRoom] = useState([])
    const [dataHotel, setDataHotel] = useState([])
    const {dates}= useContext(SearchContest)
    const [fillRoom, setFillRoom]= useState()

    const {user}= useContext(AuthContest)

    
    useEffect(() => {
        async function fetchMyAPI(){
            const res = await fetch(`https://api.komorotel.com/api/hotels/find/${hotelId}`).then((res) => res.json())
            setDataHotel(res)
        }
        fetchMyAPI()
      }, [loading])

    const handleSelect= (e) => {
        const value= e.target.value
        setSelectedRoom([value])
    }

    const getDateInRange = (start, end) => {
        const date= new Date(start.getTime())
        const list=[]

        while(date <= end){
            list.push(new Date(date).getTime())
            date.setDate(date.getDate()+1)
        }

        return list
    }
    const allDates= dates ? getDateInRange(dates[0].startDate, dates[0].endDate) : []
    const navigate = useNavigate()


    const isAvailable= (roomNumber) => {
        const isFound=roomNumber.unavailableDates.some((element, index)=>
        
        element[0].some((date) => 
            allDates.includes(new Date(date).getTime())  
            
        )
    )
        return !isFound
    }
    const handleError = (roomNumber) => {
        if(!isAvailable(roomNumber)){
            alert ("Cette chambre n'est plus disponible")
            
        }
    }
    const handleReserve = async(price, roomTitle, roomId) => {
        setFillRoom([allDates, user.name+" "+user.surname, user._id])
        
        if(selectedRoom[0]){
            if(fillRoom){
                
                try{
                    await Promise.all(
                        
                        selectedRoom.map((roomNumberId) => {
                            
                            const reservationData = {
                                guest: {
                                  name: user.name+" "+user.surname,
                                  email: user.email,
                                  id: user._id
                                },
                                hotel:{
                                    hotelName: hotel,
                                    hotelId: hotelId
                                },
                                room: {
                                  roomType: roomTitle,
                                  id: roomId,
                                  roomNumberId: roomNumberId,
                                  checkInDate: allDates[0],
                                  checkOutDate: allDates[allDates.length-1],
                                  alldate: fillRoom
                                  
                                },
                                price: price
                              
                              };
                            if(dataHotel?.pms?.apiUrl){
                                const res= axios({
                                    method: 'put',
                                    url: dataHotel?.pms?.apiUrl,
                                    headers: {
                                        'Authorization': `Bearer ${dataHotel?.pms?.apiKey}`,
                                        'Content-Type': 'application/json', 
                                    },
                                    withCredentials:true,
                                    data:{
                                        reservationData: reservationData
                                    }
                                })
                                
                            }else{
                                const res= axios({
                                    method: 'put',
                                    url: `https://api.komorotel.com/api/rooms/availability/${roomNumberId}/${user?._id}`,
                                    withCredentials:true,
                                    data:{
                                        dates:fillRoom,
                                        reservationData: reservationData
                                    }
                                })
                            }
                        }),
                        
                        
                    )
                    setOpen(false)
                    navigate("/")
                    alert("Votre réservation a été réalisée avec succès")

                }catch(err){
                    console.log(error)
                }
            }
        }else{
            alert('Veuillez selectionner une chambre!')
        }
    }

    return(
        cancelRoom ?
        <div className=" position-fixed top-50 start-50 w-100 h-100 d-flex p-5 justify-content-center align-items-center z-2" style={{"backgroundColor":"rgba(0, 0, 0, 0.418)"}}>
            <Container  className="bg-white d-none d-md-block w-50 h-50 p-2 rounded overflow-auto ">
                <button className="position-fixed start-75 m-1 btn-close" type="button" onClick={() => setOpen(false)}  aria-label="Close"></button>
                <div className="d-flex flex-column mx-3 mt-3">
                    
                        <div className="d-flex justify-content-between mx-5 mt-2 pb-7 border-bottom">
                            <div className="d-flex flex-column">
                                <p className="m-0 fw-bold">{cancelRoom.title}</p>
                                <p className="m-0 fw-light">{cancelRoom.desc}</p>
                                <p className="m-0 fw-bold" style={{"fontSize": "14px"}}>Personnes max: {cancelRoom.maxPeople}</p>
                                <p className="m-0"> <span className="fw-bold">{cancelRoom.price * days}€</span> pour {days} jours </p>
                                
                            </div>
                            
                            
                        </div>
                    
                </div>
                <Button onClick={() => handleCancel()} className="bg-danger text-white ms-7 fw-bold text-center w-75 mt-5 py-2">Annuler la réservation!</Button>

            </Container>
            <Container  className="bg-white d-md-none w-75 h-50  rounded overflow-auto ">
                <button className="position-fixed start-75 m-1 btn-close" type="button" onClick={() => setOpen(false)}  aria-label="Close"></button>
                
                <div className="d-flex flex-column mx-3 mt-3">
                    
                        <div className="d-flex justify-content-between mt-2 pb-5 border-bottom">
                            <div className="d-flex flex-column">
                                <p className="m-0 fw-bold">{cancelRoom.title}</p>
                                <p className="m-0 fw-light">{cancelRoom.desc}</p>
                                <p className="m-0 fw-bold" style={{"fontSize": "14px"}}>Personnes max: {cancelRoom.maxPeople}</p>
                                <p className="m-0"> <span className="fw-bold">{cancelRoom.price * days}€</span> pour {days} jours  </p>
                            </div>
                            
                            
                        </div>
                   
                </div>
                <Button onClick={() => handleCancel()} className="bg-danger ms-4 text-white fw-bold text-center mt-2 w-75 py-2">Annuler la réservation !</Button>

            </Container>
        </div> :
        <div className=" position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center z-2" style={{"backgroundColor":"rgba(0, 0, 0, 0.418)"}}>
            <Container  className="bg-white d-none d-md-block w-50 h-75 p-2 rounded overflow-auto ">
                <button className="position-fixed start-75 btn-close" type="button" onClick={() => setOpen(false)}  aria-label="Close"></button>
                <span className="fs-5 m-2 ms-4">Choisi ta chambre :</span>
                <div className="d-flex flex-column mx-3 mt-3 mb-2">
                    {data?.map((item) =>
                        <div>
                            <div className="mx-5 mt-2 mb-3 border-bottom">
                                <div className="d-flex justify-content-between mb-3 ">
                                    <div className="d-flex flex-column">
                                        <p className="m-0 fw-bold">{item.title}</p>
                                        <p className="m-0 fw-light">{item.desc}</p>
                                        <p className="m-0 fw-bold" style={{"fontSize": "14px"}}>Personnes max: {item.maxPeople}</p>
                                        <p className="m-0"> <span className="fw-bold">{item.price * days}€</span> pour {days} jours du <span className="fw-bold">{format(dates[0].startDate, 'dd/MM/yyyy')}</span> au <span className="fw-bold">{format(dates[0].endDate, 'dd/MM/yyyy')}</span>  </p>
                                        
                                    </div>
                                    <div className="d-flex">
                                        {item.roomNumbers.map((roomNumber) => 
                                            <div className="mx-2 d-flex flex-column">
                                                <label className="text-light">{roomNumber.number}</label>
                                                <div className="w-50 h-25" style={{cursor: "pointer"}} onClick={() => handleError(roomNumber)}><input name="room" type="radio" value={roomNumber._id} disabled={!isAvailable(roomNumber)}   onChange={handleSelect} /></div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Button onClick={() => handleReserve(item.price * days, item.title, item._id)} className="bg-primary text-white ms-7 fw-bold text-center w-75 mt-3 py-2">Réservez maintenant !</Button>
                                <p className="text-center fw-light">Cliquez deux fois pour valider !</p>
                            </div>
                            
                        </div>
                    )}
                </div>

            </Container>
            <Container  className="bg-white d-md-none w-75 h-75 p-2 rounded overflow-auto ">
                <button className="position-fixed start-75 btn-close m-1" type="button" onClick={() => setOpen(false)}  aria-label="Close"></button>
                <span className="fs-5 m-2">Choisi ta chambre :</span>
                <div className="d-flex flex-column mx-3 mt-3">
                    {data?.map((item) =>
                        <div>
                            <div className=" mt-2 mb-3 border-bottom">
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex flex-column">
                                        <p className="m-0 fw-bold">{item.title}</p>
                                        <p className="m-0 fw-light">{item.desc}</p>
                                        <p className="m-0 fw-bold" style={{"fontSize": "14px"}}>Personnes max: {item.maxPeople}</p>
                                        <p className="m-0"> <span className="fw-bold">{item.price * days}€</span> pour {days} jours du <span className="fw-bold">{format(dates[0].startDate, 'dd/MM/yyyy')}</span> au <span className="fw-bold">{format(dates[0].endDate, 'dd/MM/yyyy')}</span>  </p>
                                    </div>
                                    <div className="d-flex">
                                        {item.roomNumbers.map((roomNumber) => 
                                            <div className="mx-2 w-50 d-flex flex-column">
                                                <label className="text-light">{roomNumber.number}</label>
                                                <div className="w-50 h-25" style={{cursor: "pointer"}} onClick={() => handleError(roomNumber)}><input name="room" type="radio" value={roomNumber._id} disabled={!isAvailable(roomNumber)}   onChange={handleSelect} /></div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Button onClick={() => handleReserve(item.price * days, item.title, item._id)} className="bg-primary text-white ms-4 fw-bold text-center w-75 mt-2 py-2">Réservez maintenant !</Button>
                                <p className="text-center fw-light">Cliquez deux fois pour valider !</p>
                            </div>
                            
                        </div>
                    )}
                </div>

            </Container>
        </div>
    )
}