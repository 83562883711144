import { useNavigate, useParams } from "react-router-dom"
import useFetch from "../../clients/features/get_withcredentials"
import { Button } from "react-bootstrap"
import { format } from "date-fns"
import axios from "axios"

export default function Bills(){
    const hotelParams= useParams()
    const hotelId= hotelParams.hotelId
    const navigate= useNavigate()

    const {data, loading, error, refreshData}= useFetch(`https://api.komorotel.com/api/bills/${hotelId}`)

    const handleValidate= async(id, user, price) => {

        try{
            
                await fetch(`https://api.komorotel.com/api/bills/validate/${id}`,{
                    method:'PUT',
                    credentials:'include',
                    headers:{'Content-Type':'application/json'},
                    
                    
                      }).then(response => {
                        if(response.status===401){
                            alert("Vous n'êtes pas autorisé! Reconnectez-vous")
                            navigate("/admin")
                        }}).catch(error => console.log(error))
                await axios({
                        method:'put',
                        url: `https://api.komorotel.com/api/earn/increase`,
                        withCredentials:true,
                        data:{
                            hoetlId: hotelId,
                            price:price,
                            user: user,
                            month: new Date().getMonth()
                          }
          
                      })
            
            refreshData()
        }catch(err){
            console(err)
        }
    } 
    const handleCancel= async(roomId, hotelId, dates, price, user, id) => {
        try{

                await axios({
                    method: 'delete',
                    withCredentials:true,
                    url: `https://api.komorotel.com/api/rooms/availability/delete/${roomId}`,
                    data:{
                        dates:dates
                    }
                })
                
                await axios({
                    method:'put',
                    url: `https://api.komorotel.com/api/bills/${id}`,
                    withCredentials:true,
                    
                })
                
                await axios({
                    method:'put',
                    url: `https://api.komorotel.com/api/earn/decrease`,
                    withCredentials:true,
                    data:{
                        hotelId: hotelId,
                        price:price,
                        user: user,
                        month: new Date().getMonth()
                    }

                })
            
            refreshData()
        }catch(err){
            console.log(err)
        }
    }


    return(
        loading  ? <div class="spinner-border bg-secondary align-middle text-primary" role="status"> </div>:
        <div className="w-100 h-100 bg-secondary">
            <h1 className="text-center pt-3">Bills List</h1>
            <div className="d-flex flex-column align-items-center justify-content-center  w-100 mt-5">
                
                {data?.map((bill)=>

                    bill.deleted===false &&
                        <div className="d-flex flex-column bg-white justify-content-center w-50 border fs-6 text-center rounded m-2 p-3 shadow-sm">
                        <p className="fs-5 fw-bold text-center pb-3 border-bottom">{bill.room.roomType}</p>
                        <div className="d-flex fs-6  "><p className="fw-bold">Nom :</p> <p className="ms-4"> {bill.guest.name} </p></div>
                        <div className="d-flex fs-6 pb-3 border-bottom "><p className="fw-bold">Email :</p> <p className="ms-4"> {bill.guest.email} </p></div>

                        <div className="d-flex fs-6 mt-3  "><p className="fw-bold">Date :</p> <p className="ms-4">Du <span className="fw-bold">{format(bill.room.checkInDate, 'dd/MM/yyyy')}</span> au <span className="fw-bold">{format(bill.room.checkOutDate, 'dd/MM/yyyy')}</span> </p></div>
                        <div className="d-flex fs-6  "><p className="fw-bold">Montant de la facture :</p> <p className="ms-4"> <span className="fw-bold">{bill.price} €</span> </p></div>

                        {bill.validated=== false && 
                        <div className="d-flex justify-content-between mt-3 ">
                                <div><Button className="text-center bg-primary text-white fw-bold h-100  " onClick={() => handleValidate(bill._id, bill.guest.name, bill.price )} >Valider la venue</Button></div>
                                <div><Button className="text-center bg-danger text-white fw-bold h-100  " onClick={() => handleCancel(bill.room.roomNumberId, bill.hotel.hotelId, bill.room.alldate, bill.price, bill.guest.name, bill._id )} >Annuler la venue <br /> (Client n'est pas venu)</Button></div>
                        </div>
                        }
                        </div>
                )}
                <p className="m-0 fw-bold rounded-5 my-2 bg-white p-2 text-center border shadow-sm">Aucune autres factures</p> 

            </div>
        </div>
        )
}